<template>
  <div class="w-full pt-5">
    <div class="flex w-full">
      <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
        Performance Agreements
      </h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>

    <div style="height: 100%" class="mt-28 pt-10" v-if="loadingData">
      <loader size="xxs" />
    </div>

    <div v-else class="w-full p-5 flex mt-1 flex-col">
      <Tabs
        class="mt-6 mx-3"
        :tabs="tabsItems"
        :active-tab="activeTab"
        @currentTab="activeTab = $event"
        :border="true"
        v-if="tabsItems.length > 0"
      />
      <div class="w-full flex flex-col">
        <OrgGoals v-if="activeTab === 'Organization Goals'" />
        <MyAgreements v-if="activeTab === 'My Agreements'" />
      </div>

      <div class="w-full" v-if="tabsItems.length === 0">
        <div class="w-full flex flex-col justify-center items-center mt-20">
          <icon icon-name="empty_agreement" size="l" style="width: 300px" />
          <div class="w-1/2 text-base text-center">
            No performance agreements displayed. Added agreements will be
            displayed once they are added.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import Loader from "@/components/Loader";
import Icon from "@/components/Icon";
import RightSideBar from "@/components/RightSideBar";
import Menu from "@/components/Menu";
import MyAgreements from "./MyAgreements";
import OrgGoals from "./OrgGoals";

export default {
  name: "Function",
  components: {
    Menu,
    Tabs,
    Breadcrumb,
    Loader,
    Icon,
    RightSideBar,
    MyAgreements,
    OrgGoals,
  },
  data() {
    return {
      perfArray: [],
      openModal: false,
      kpaSummary: {},
      loadingData: true,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Agreements",
          href: "EssAgreements",
          id: "Agreements",
        },
      ],
      tabsItems: [],
      activeTab: this.$route.query.activeTab,
      manager: true,
      reportingTo: false,
    };
  },

  methods: {
    activateTab(tab) {
      this.activeTab = tab;
      this.$router
        .push({ name: "EssAgrreements", query: { activeTab: tab } })
        .catch(() => {});
    },

    getUserReportingTo() {
      const payload = { id: this.$AuthUser.id };

      this.$_getOneEmployeeAll(payload).then((response) => {
        this.reportingTo = !!response.data.userReportingTo;

        this.getUserDirectReports(this.reportingTo);
      });
    },

    getUserDirectReports(reportingTo) {
      this.$_getManagerDirectReport(this.$AuthUser.id).then((result) => {
        if (result.data.directReport.length === 0 && reportingTo) {
          this.getGoals(reportingTo, result.data.directReport);
          this.manager = false;
        } else {
          this.manager = true;
          if (!reportingTo) {
            this.tabsItems.push(
              "Organization Goals"
            );

            this.activeTab = this.$route.query.activeTab
              ? this.$route.query.activeTab
              : "Organization Goals";

            this.loadingData = false;
          } else {
            this.loadingData = false;

            this.getGoals(reportingTo, result.data.directReport);
          }
        }
      });
    },

    getGoals(reportingTo, directReports) {
      this.shortTermGoals = [];
      this.$_getOrganizationGoal('').then((response) => {
        const goalData = response.data.goals;

        goalData.forEach((goals) => {
          if (this.tabsItems.length === 0) {
            if (
              goals.goal.sharedEmployees.includes(this.$AuthUser.id) &&
              reportingTo &&
              directReports.length > 0
            ) {
              this.tabsItems.push(
                "Organization Goals",
                "My Agreements"
              );
              this.activeTab = this.$route.query.activeTab
                ? this.$route.query.activeTab
                : "Organization Goals";
            } else if (
              !goals.goal.sharedEmployees.includes(this.$AuthUser.id) &&
              reportingTo &&
              directReports.length > 0
            ) {
              this.tabsItems.push("My Agreements");
              this.activeTab = this.$route.query.activeTab
                ? this.$route.query.activeTab
                : "My Agreements";
            } else if (
              goals.goal.sharedEmployees.includes(this.$AuthUser.id) &&
              reportingTo &&
              directReports.length === 0
            ) {
              this.tabsItems.push("Organization Goals", "My Agreements");
              this.activeTab = this.$route.query.activeTab
                ? this.$route.query.activeTab
                : "Organization Goals";
            } else if (
              !goals.goal.sharedEmployees.includes(this.$AuthUser.id) &&
              reportingTo &&
              directReports.length === 0
            ) {
              this.tabsItems.push("My Agreements");
              this.activeTab = this.$route.query.activeTab
                ? this.$route.query.activeTab
                : "My Agreements";
            }
          }

          this.loadingData = false;
        });

        if (goalData.length === 0) {
          this.loadingData = false;
        }
      });
    },
  },

  mounted() {
    this.getUserReportingTo();
  },
};
</script>

<style scoped>
.custom-radius {
  border-radius: 7px;
}
.button:focus {
  outline: none;
}
</style>